export const clusterTypes = [
  'gke',
  'aks',
  'eks',
  'kubernetes',
  'openshift',
  'rancher',
  'digitalocean',
];

export const AUTHORIZED_DOMAINS = ['cloudoor.com', 'localhost', 'door.cloud', 'cno.app', 'gocno.io', '127.0.0.1'];
export const ACCESS_MODES = ['ReadWriteOnce', 'ReadOnlyMany', 'ReadWriteMany'];

export const CAPABILITIES = [
  'ALL',
  'AUDIT_CONTROL',
  'AUDIT_READ',
  'AUDIT_WRITE',
  'BLOCK_SUSPEND',
  'BPF',
  'CHECKPOINT_RESTORE',
  'CHOWN',
  'DAC_OVERRIDE',
  'DAC_READ_SEARCH',
  'FOWNER',
  'FSETID',
  'IPC_LOCK',
  'IPC_OWNER',
  'KILL',
  'LEASE',
  'LINUX_IMMUTABLE',
  'MAC_ADMIN',
  'MAC_OVERRIDE',
  'MKNOD',
  'NET_ADMIN',
  'NET_BIND_SERVICE',
  'NET_BROADCAST',
  'NET_RAW',
  'PERFMON',
  'SETFCAP',
  'SETGID',
  'SETPCAP',
  'SETUID',
  'SYS_ADMIN',
  'SYS_BOOT',
  'SYS_CHROOT',
  'SYS_MODULE',
  'SYS_NICE',
  'SYS_PACCT',
  'SYS_PTRACE',
  'SYS_RAWIO',
  'SYS_RESOURCE',
  'SYS_TIME',
  'SYS_TTY_CONFIG',
  'SYSLOG',
  'WAKE_ALARM',
];

export const TYPE_VOLUMES = [
  { label: 'EmptyDir', value: 'emptyDir' },
  { label: 'ConfigMap', value: 'configMap' },
  { label: 'Secret', value: 'secret' },
  { label: 'PVC', value: 'pvc' },
];

export const TOLERATION_OPERATORS = [
  { label: 'Equal', value: 'Equal' },
  { label: 'Exists', value: 'Exists' },
];

export const TOLERATION_EFFETS = [
  { label: 'NoSchedule', value: 'NoSchedule' },
  { label: 'PreferNoSchedule', value: 'PreferNoSchedule' },
  { label: 'NoExecute', value: 'NoExecute' },
];


export const title =
  'If you want to register Openshift, Barc Metal or any Kubernetes cluster from any cloud provider.';

export const SIZE_SCREEN = 1024;

export const DEFAULT_ORGANIZATION: string = 'default';
export const SUPPORT_EMAIL: string = 'support@gocno.io';

export const CHAGEBEE_PLANS = {
  free: 'free',
  teamsMonthly: 'teams-monthly',
  teamsYearly: 'teams-yearly',
  proMonthly: 'pro-monthly',
  proYearly: 'pro-yearly',
};

export const CHAGEBEE_STATUS_ACCESS = ['in_trial', 'active'];

export const CHAGEBEE_STATUS_REJECT = [
  'future',
  'non_renewing',
  'paused',
  'cancelled',
];

export const SUBSCRIPTION_DEFAULT_STATUS = 'waiting_subscription';

export const RESOURCES = {
  organization: {
    name: 'organization',
    role: {
      selfProvisioner: 'self_provisioner',
      viewer: 'viewer',
      owner: 'owner',
      validator: 'validator',
      superAdmin: 'super_admin',
    },
  },
  project: {
    name: 'project',
    role: {
      admin: 'admin',
      developer: 'developer',
      viewer: 'viewer',
    },
  },
};
export const typeTags = ['text', 'selection'];

export const unitValues = {
  m: 0.001,
  core: 1,
  Mi: 1048576, // 2^20
  Gi: 1073741824, // 2^30
  M: 1000000,
  G: 1000000000,
};

export const getRandomColor = (index: number) => {
  const letters = '0123456789ABCDEF' + index;
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const regions = [
  {
    label: 'USA Est (Virginie du Nord)',
    name: 'us-east-1',
    cloudprovider: 'aws',
  },
  {
    label: 'USA Est (Ohio)',
    name: 'us-east-2',
    cloudprovider: 'aws',
  },
  {
    label: 'USA Ouest (Californie du Nord)',
    name: 'us-west-1',
    cloudprovider: 'aws',
  },
  {
    label: 'USA Ouest (Oregon)',
    name: 'us-west-2',
    cloudprovider: 'aws',
  },
  {
    label: 'Afrique (Le Cap)',
    name: 'af-south-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Hong Kong)',
    name: 'ap-east-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Mumbai)',
    name: 'ap-south-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Osaka)',
    name: 'ap-northeast-3',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Séoul)',
    name: 'ap-northeast-2',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Singapour)',
    name: 'ap-southeast-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Sydney)',
    name: 'ap-southeast-2',
    cloudprovider: 'aws',
  },
  {
    label: 'Asie Pacifique (Tokyo)',
    name: 'ap-northeast-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Canada (Central)',
    name: 'ca-central-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Francfort)',
    name: 'eu-central-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Irlande)',
    name: 'eu-west-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Londres)',
    name: 'eu-west-2',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Milan)',
    name: 'eu-south-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Paris)',
    name: 'eu-west-3',
    cloudprovider: 'aws',
  },
  {
    label: 'Europe (Stockholm)',
    name: 'eu-north-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Moyen-Orient (Bahreïn)',
    name: 'me-south-1',
    cloudprovider: 'aws',
  },
  {
    label: 'Amérique du Sud (São Paulo)',
    name: 'sa-east-1',
    cloudprovider: 'aws',
  },
  { name: 'us-west1', cloudprovider: 'gcp' },
  { label: 'us-west1', name: 'us-west1-a', cloudprovider: 'gcp' },
  { label: 'us-west1', name: 'us-west1-b', cloudprovider: 'gcp' },
  { label: 'us-west1', name: 'us-west1-c', cloudprovider: 'gcp' },
  { name: 'us-west2', cloudprovider: 'gcp' },
  { label: 'us-west2', name: 'us-west2-a', cloudprovider: 'gcp' },
  { label: 'us-west2', name: 'us-west2-b', cloudprovider: 'gcp' },
  { label: 'us-west2', name: 'us-west2-c', cloudprovider: 'gcp' },
  { name: 'us-west3', cloudprovider: 'gcp' },
  { label: 'us-west3', name: 'us-west3-a', cloudprovider: 'gcp' },
  { label: 'us-west3', name: 'us-west3-b', cloudprovider: 'gcp' },
  { label: 'us-west3', name: 'us-west3-c', cloudprovider: 'gcp' },
  { name: 'us-west4', cloudprovider: 'gcp' },
  { label: 'us-west4', name: 'us-west4-a', cloudprovider: 'gcp' },
  { label: 'us-west4', name: 'us-west4-b', cloudprovider: 'gcp' },
  { label: 'us-west4', name: 'us-west4-c', cloudprovider: 'gcp' },
  { name: 'us-central1', cloudprovider: 'gcp' },
  { label: 'us-central1', name: 'us-central1-a', cloudprovider: 'gcp' },
  { label: 'us-central1', name: 'us-central1-b', cloudprovider: 'gcp' },
  { label: 'us-central1', name: 'us-central1-c', cloudprovider: 'gcp' },
  { name: 'us-east1', cloudprovider: 'gcp' },
  { label: 'us-east1', name: 'us-east1-a', cloudprovider: 'gcp' },
  { label: 'us-east1', name: 'us-east1-b', cloudprovider: 'gcp' },
  { label: 'us-east1', name: 'us-east1-c', cloudprovider: 'gcp' },
  { name: 'us-east4', cloudprovider: 'gcp' },
  { label: 'us-east4', name: 'us-east4-a', cloudprovider: 'gcp' },
  { label: 'us-east4', name: 'us-east4-b', cloudprovider: 'gcp' },
  { label: 'us-east4', name: 'us-east4-c', cloudprovider: 'gcp' },
  { name: 'northamerica-northeast1', cloudprovider: 'gcp' },
  {
    label: 'northamerica-northeast1',
    name: 'northamerica-northeast1-a',
    cloudprovider: 'gcp',
  },
  {
    label: 'northamerica-northeast1',
    name: 'northamerica-northeast1-b',
    cloudprovider: 'gcp',
  },
  {
    label: 'northamerica-northeast1',
    name: 'northamerica-northeast1-c',
    cloudprovider: 'gcp',
  },
  { name: 'southamerica-east1', cloudprovider: 'gcp' },
  {
    label: 'southamerica-east1',
    name: 'southamerica-east1-a',
    cloudprovider: 'gcp',
  },
  {
    label: 'southamerica-east1',
    name: 'southamerica-east1-b',
    cloudprovider: 'gcp',
  },
  {
    label: 'southamerica-east1',
    name: 'southamerica-east1-c',
    cloudprovider: 'gcp',
  },
  { name: 'europe-west2', cloudprovider: 'gcp' },
  { label: 'europe-west2', name: 'europe-west2-a', cloudprovider: 'gcp' },
  { label: 'europe-west2', name: 'europe-west2-b', cloudprovider: 'gcp' },
  { label: 'europe-west2', name: 'europe-west2-c', cloudprovider: 'gcp' },
  { name: 'europe-west1', cloudprovider: 'gcp' },
  { label: 'europe-west1', name: 'europe-west1-a', cloudprovider: 'gcp' },
  { label: 'europe-west1', name: 'europe-west1-b', cloudprovider: 'gcp' },
  { label: 'europe-west1', name: 'europe-west1-c', cloudprovider: 'gcp' },
  { name: 'europe-west4', cloudprovider: 'gcp' },
  { label: 'europe-west4', name: 'europe-west4-a', cloudprovider: 'gcp' },
  { label: 'europe-west4', name: 'europe-west4-b', cloudprovider: 'gcp' },
  { label: 'europe-west4', name: 'europe-west4-c', cloudprovider: 'gcp' },
  { name: 'europe-west6', cloudprovider: 'gcp' },
  { label: 'europe-west6', name: 'europe-west6-a', cloudprovider: 'gcp' },
  { label: 'europe-west6', name: 'europe-west6-b', cloudprovider: 'gcp' },
  { label: 'europe-west6', name: 'europe-west6-c', cloudprovider: 'gcp' },
  { name: 'europe-west3', cloudprovider: 'gcp' },
  { label: 'europe-west3', name: 'europe-west3-a', cloudprovider: 'gcp' },
  { label: 'europe-west3', name: 'europe-west3-b', cloudprovider: 'gcp' },
  { label: 'europe-west3', name: 'europe-west3-c', cloudprovider: 'gcp' },
  { name: 'europe-north1', cloudprovider: 'gcp' },
  { label: 'europe-north1', name: 'europe-north1-a', cloudprovider: 'gcp' },
  { label: 'europe-north1', name: 'europe-north1-b', cloudprovider: 'gcp' },
  { label: 'europe-north1', name: 'europe-north1-c', cloudprovider: 'gcp' },
  { name: 'asia-south1', cloudprovider: 'gcp' },
  { label: 'asia-south1', name: 'asia-south1-a', cloudprovider: 'gcp' },
  { label: 'asia-south1', name: 'asia-south1-b', cloudprovider: 'gcp' },
  { label: 'asia-south1', name: 'asia-south1-c', cloudprovider: 'gcp' },
  { name: 'asia-southeast1', cloudprovider: 'gcp' },
  { label: 'asia-southeast1', name: 'asia-southeast1-a', cloudprovider: 'gcp' },
  { label: 'asia-southeast1', name: 'asia-southeast1-b', cloudprovider: 'gcp' },
  { label: 'asia-southeast1', name: 'asia-southeast1-c', cloudprovider: 'gcp' },
  { name: 'asia-southeast2', cloudprovider: 'gcp' },
  { label: 'asia-southeast2', name: 'asia-southeast2-a', cloudprovider: 'gcp' },
  { label: 'asia-southeast2', name: 'asia-southeast2-b', cloudprovider: 'gcp' },
  { label: 'asia-southeast2', name: 'asia-southeast2-c', cloudprovider: 'gcp' },
  { name: 'asia-east2', cloudprovider: 'gcp' },
  { label: 'asia-east2', name: 'asia-east2-a', cloudprovider: 'gcp' },
  { label: 'asia-east2', name: 'asia-east2-b', cloudprovider: 'gcp' },
  { label: 'asia-east2', name: 'asia-east2-c', cloudprovider: 'gcp' },
  { name: 'asia-east1', cloudprovider: 'gcp' },
  { label: 'asia-east1', name: 'asia-east1-a', cloudprovider: 'gcp' },
  { label: 'asia-east1', name: 'asia-east1-b', cloudprovider: 'gcp' },
  { label: 'asia-east1', name: 'asia-east1-c', cloudprovider: 'gcp' },
  { name: 'asia-northeast1', cloudprovider: 'gcp' },
  { label: 'asia-northeast1', name: 'asia-northeast1-a', cloudprovider: 'gcp' },
  { label: 'asia-northeast1', name: 'asia-northeast1-b', cloudprovider: 'gcp' },
  { label: 'asia-northeast1', name: 'asia-northeast1-c', cloudprovider: 'gcp' },
  { name: 'asia-northeast2', cloudprovider: 'gcp' },
  { label: 'asia-northeast2', name: 'asia-northeast2-a', cloudprovider: 'gcp' },
  { label: 'asia-northeast2', name: 'asia-northeast2-b', cloudprovider: 'gcp' },
  { label: 'asia-northeast2', name: 'asia-northeast2-c', cloudprovider: 'gcp' },
  { name: 'asia-northeast3', cloudprovider: 'gcp' },
  { label: 'asia-northeast3', name: 'asia-northeast3-a', cloudprovider: 'gcp' },
  { label: 'asia-northeast3', name: 'asia-northeast3-b', cloudprovider: 'gcp' },
  { label: 'asia-northeast3', name: 'asia-northeast3-c', cloudprovider: 'gcp' },
  { name: 'australia-southeast1', cloudprovider: 'gcp' },
  {
    label: 'australia-southeast1',
    name: 'australia-southeast1-a',
    cloudprovider: 'gcp',
  },
  {
    label: 'australia-southeast1',
    name: 'australia-southeast1-b',
    cloudprovider: 'gcp',
  },
  {
    label: 'australia-southeast1',
    name: 'australia-southeast1-c',
    cloudprovider: 'gcp',
  },
  { label: 'eastus', name: 'eastus', cloudprovider: 'azure' },
  { label: 'eastus2', name: 'eastus2', cloudprovider: 'azure' },
  { label: 'southcentralus', name: 'southcentralus', cloudprovider: 'azure' },
  { label: 'westus3', name: 'westus3', cloudprovider: 'azure' },
  { label: 'australiaeast', name: 'australiaeast', cloudprovider: 'azure' },
  { label: 'southeastasia', name: 'southeastasia', cloudprovider: 'azure' },
  { label: 'northeurope', name: 'northeurope', cloudprovider: 'azure' },
  { label: 'swedencentral', name: 'swedencentral', cloudprovider: 'azure' },
  { label: 'uksouth', name: 'uksouth', cloudprovider: 'azure' },
  { label: 'westeurope', name: 'westeurope', cloudprovider: 'azure' },
  { label: 'centralus', name: 'centralus', cloudprovider: 'azure' },
  { label: 'francecentral', name: 'francecentral', cloudprovider: 'azure' },
  {
    label: 'southafricanorth',
    name: 'southafricanorth',
    cloudprovider: 'azure',
  },
  { label: 'centralindia', name: 'centralindia', cloudprovider: 'azure' },
  { label: 'eastasia', name: 'eastasia', cloudprovider: 'azure' },
  { label: 'japaneast', name: 'japaneast', cloudprovider: 'azure' },
  { label: 'koreacentral', name: 'koreacentral', cloudprovider: 'azure' },
  { label: 'italynorth', name: 'italynorth', cloudprovider: 'azure' },
  { label: 'canadacentral', name: 'canadacentral', cloudprovider: 'azure' },
  {
    label: 'germanywestcentral',
    name: 'germanywestcentral',
    cloudprovider: 'azure',
  },
  { label: 'norwayeast', name: 'norwayeast', cloudprovider: 'azure' },
  { label: 'polandcentral', name: 'polandcentral', cloudprovidder: 'azure' },
  {
    label: 'switzerlandnorth',
    name: 'switzerlandnorth',
    cloudprovider: 'azure',
  },
  { label: 'uaenorth', name: 'uaenorth', cloudprovider: 'azure' },
  { label: 'brazilsouth', name: 'brazilsouth', cloudprovider: 'azure' },
  { label: 'israelcentral', name: 'israelcentral', cloudprovider: 'azure' },
  { label: 'qatarcentral', name: 'qatarcentral', cloudprovider: 'azure' },
  { label: 'centraluseuap', name: 'centraluseuap', cloudprovider: 'azure' },
  { label: 'westus2', name: 'westus2', cloudprovider: 'azure' },
];

export enum Type {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface Options {
  type: Type;
  position: Position;
  title: string;
  message: string;
}

export function generateRandomString(length: number) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
