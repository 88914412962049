import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { InviteMemberComponent } from '../popups/invite-member/invite-member.component';
import { UpgradePlanComponent } from '../popups/upgrade-plan/upgrade-plan.component';
import { MessagingAccountComponent } from '../popups/messaging-account/messaging-account.component';

import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ShareService } from '../../data/service/share.service';
import { Router } from '@angular/router';

import { AppAuthGuardService } from '../../data/service/app-auth-guard.service';
import { LoginService } from '../../auth/pages/login/services/login.service';
import { Position, SUPPORT_EMAIL, Type } from 'src/app/utils/const';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { CommandCnoctlComponent } from '../popups/command-cnoctl/command-cnoctl.component';
import { Session } from 'src/app/data/model/Session';
import { ConfigSamlComponent } from '../popups/config-saml/config-saml.component';
import { removeFilterStorage } from 'src/app/utils/app-init';
import { ClusterService } from 'src/app/modules/clusters/services/cluster.service';
import { Organization } from 'src/app/data/model/organization';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  showMenu = false;
  currentUser: any;
  organizationControlForm: FormControl;
  navbarCollapsed = true;
  currentSession: any;
  currentMenu: string;
  subscriptionType = 'pro';
  onlyClusterShared = false;
  supportEmail: string = SUPPORT_EMAIL;
  isOpendedDropdown = false;

  organizations: Organization[] = [];
  organizationSearch: string = '';
  organizationSelected: Organization;
  displaySuggesions = false;

  constructor(
    private asideExtenderService: AsideExtenderService,
    public shareService: ShareService,
    private router: Router,
    private clusterService: ClusterService,
    public auth: AppAuthGuardService,
    private loginService: LoginService,
    private modalService: BsModalService
  ) {
    this.organizations = this.shareService.organizations;
    this.organizationSelected = this.shareService.organizationSelected;
    this.organizationSearch = this.organizationSelected.name;
  }

  async onGotoApim() {
    localStorage.setItem('callbackUrl', environment.apimRedirect);
    this.loginService.goToCallbackUrl();
  }

  ngOnInit(): void {
    const getCurrentUser = this.shareService.getSession();
    if (getCurrentUser) {
      this.currentUser = getCurrentUser.currentUser;
      this.initDataOrg();
    }
    this.getClusters();
    this.selectedMenu();
  }

  ngOnDestroy(): void {
    this.currentMenu = '';
  }
  onCustomAction() {
    this.router.navigate(['/login-apim']);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    this.organizationSearch = this.organizationSelected.name;
    const target = event.target as HTMLElement;
    if (!target.closest('.container-org')) {
      this.displaySuggesions = false;
    }
  }

  filterOrganizations() {
    this.displaySuggesions = true;
    if (!this.organizationSearch) {
      this.organizations = this.shareService.organizations;
    } else {
      this.organizations = this.shareService.organizations.filter((org) =>
        org.name.toLowerCase().includes(this.organizationSearch.toLowerCase())
      );
    }
  }

  // Sélectionner un élément de la liste
  selectOrganization(org: Organization) {
    this.organizationSelected = org;
    this.organizationSearch = org.name;
    if (org) {
      this.displaySuggesions = false;
      this.loginService.getCurrentUser().subscribe(
        (data) => {
          this.currentSession.currentUser = data;
          this.shareService.updateSession(this.currentSession);
          this.shareService.email = this.currentSession.currentUser.email;
          this.shareService.setOrganizationSelected(org, this.currentSession);

          if (environment.local) {
            window.location.href =
              'http://' + environment.baseUrl + '/#/dashboard';
            window.location.reload();
          } else {
            const exceptOrg = ['default'];

            if (exceptOrg.includes(org.name) || org?.name?.includes('@')) {
              const url = `${environment.schemas}://${environment.baseUrl}/#/dashboard`;
              window.location.replace(url);
            } else {
              const url = `${environment.schemas}://${org.name}.${environment.baseUrl}/#/dashboard`;
              window.location.replace(url);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }

    this.organizations = [];
  }

  canShowDomainMenu() {
    if (this.currentUser.email == this.supportEmail) {
      return true;
    }
    if (
      (this.auth?.hasOrganizationRoles('super_admin') ||
        this.auth?.hasOrganizationRoles('owner')) &&
      !this.onlyClusterShared
    ) {
      return true;
    }

    return false;
  }
  getClusters(): void {
    this.clusterService.getAll().subscribe((data) => {
      const clusters = data.records;
      if (this.currentUser.email != this.supportEmail) {
        if (clusters.length == 1 && clusters.find((c) => c.shared)) {
          this.onlyClusterShared = true;
        }
      }
    });
  }

  onOpenDropdownChange(isOpened: boolean) {
    this.isOpendedDropdown = isOpened;
  }

  selectedMenu() {
    let currentUrl = window.location.href;

    if (currentUrl.includes('deliver')) {
      this.currentMenu = 'deliver';
    } else if (currentUrl.includes('deploy')) {
      this.currentMenu = 'deploy';
    } else if (currentUrl.includes('deploy')) {
      this.currentMenu = 'apps';
    } else if (currentUrl.includes('member')) {
      this.currentMenu = 'member';
    } else if (currentUrl.includes('project')) {
      this.currentMenu = 'project';
    } else {
      this.currentMenu = '';
    }
  }

  initDataOrg() {
    this.currentSession = JSON.parse(
      localStorage.getItem('session')
    ) as Session;
    this.organizationControlForm = new FormControl(
      this.shareService.organizationSelected.id
    );
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onManageNotification(): void {
    this.onToggleProfileMenu();
    this.showMenu = false;
    const config: ModalOptions = { class: 'popup-right-side' };
    this.modalService.show(MessagingAccountComponent, config);
  }

  onCommandCnoctl(): void {
    const config: ModalOptions = { class: 'popup-right-side' };
    this.modalService.show(CommandCnoctlComponent, config);
  }

  onDiscordCommunity(): void {
    this.showMenu = false;
    window.open('https://discord.gg/99QCePkA5f', '_blank');
  }

  onUpgradePlan(): void {
    this.showMenu = false;
    const config: ModalOptions = {
      class: 'modal-xl modal-dialog-centered p-5',
      backdrop: 'static',
      animated: true,
    };
    this.modalService.show(UpgradePlanComponent, config);
  }

  onConfigSAML() {
    this.onToggleProfileMenu();
    this.showMenu = false;
    const config: any = {
      class: 'popup-right-side-small',
      initialState: {
        organization: this.shareService.organizationSelected,
      },
    };
    this.modalService.show(ConfigSamlComponent, config);
  }

  onToggleProfileMenu(): void {
    this.showMenu = !this.showMenu;
  }
  onCloseProfileMenu(): void {
    this.showMenu = false;
  }
  onLogout(): void {
    this.shareService.logout();
  }
  onSlackCommunity() {
    this.currentMenu = '';
    this.showMenu = false;
    this.onToggleProfileMenu();
  }

  onResetPassword() {
    this.currentMenu = '';
    this.showMenu = false;
    this.router.navigate(['/auth/change-password']);
  }

  onOrganization() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/organizations/list']);
  }

  onCluster() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/clusters']);
  }
  onCloudProvider() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/parameters/cloud-providers']);
  }

  onPorject() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/onboard']);
  }
  onMembers() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/members/list']);
  }

  onQuota() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/parameters/quota']);
  }

  onTag() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/parameters/tagging']);
  }
  onDomain() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/domain']);
  }

  onRegistryClick() {
    this.currentMenu = '';
    this.onToggleProfileMenu();
    removeFilterStorage();
    this.showMenu = false;
    this.router.navigate(['/registry']);
  }
  onChangePassword(): void {
    const email = this.currentUser.email;
    this.loginService
      .ForgotPassword(email)
      .then((res) => {
        this.asideExtenderService.show({
          type: Type.SUCCESS,
          message: 'You will receive instructions by mail',
          title: 'Change Password',
          position: Position.TOP,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }
  ngAfterViewInit() {}

  showDiv() {
    this.showMenu = true;
  }

  hideDiv() {
    this.showMenu = false;
  }
}
