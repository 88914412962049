import { jwtDecode } from 'jwt-decode';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from 'src/app/data/service/share.service';
import { LoginService } from 'src/app/auth/pages/login/services/login.service';
import { Session } from 'src/app/data/model/Session';

export const tokenHasExpired = async () => {
  const shareService = inject(ShareService);
  const loginService = inject(LoginService);
  const router = inject(Router);

  try {
    const session = shareService.getSession();
    const accessToken = session?.access_token;
    const refreshToken = session?.refresh_token;

    if (accessToken && refreshToken) {
      const decodedToken = decodeJWT(accessToken);

      if (decodedToken) {
        const date = new Date();

        date.setMinutes(date.getMinutes() + 5);

        // const isExpired = date.getTime() > decodedToken.exp * 1000;

        if (isExpired(session)) {
          await loginService
            .refresh(session.refresh_token)
            .toPromise()
            .then(async (newSession) => {
              newSession.currentUser = session.currentUser;
              shareService.setSession(newSession);
              newSession.currentUser = await loginService
                .getCurrentUser()
                .toPromise();
              shareService.updateSession(newSession);
              return true;
            })
            .catch((error) => {
              shareService.removeSession();
              router.navigateByUrl('/auth/login/');
              return false;
            });
        } else {
          return true;
        }
      }
    } else {
      router.navigateByUrl('/auth/login/');
      return false;
    }
  } catch (error) {
    console.error('Error while checking token expiration:', error);
    router.navigateByUrl('/auth/login/');
    return false;
  }
};

export const isExpired = (session: Session) => {
  const decodedToken = decodeJWT(session.access_token);

  if (decodedToken) {
    const date = new Date();

    date.setMinutes(date.getMinutes() + 5);

    const isExpired = date.getTime() > decodedToken.exp * 1000;
    if (isExpired) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

function decodeJWT(token: string): any | null {
  try {
    const decoded: any = jwtDecode(token);

    if (decoded) {
      return decoded;
    } else {
      console.error('The JWT is invalid or could not be decoded.');
      return decoded;
    }
  } catch (error: any) {
    console.error('JWT decoding error :', error.message);
    return null;
  }
}
